#top .hero {
  width: 100vw;
  height: calc(100vh - 80px);
  background-image: url("./../img/top/top_fv@2x.png");
  background-size: cover;
  background-position: bottom center;
  background-color: #8a5045;
  position: relative;
}
#top .hero__content {
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}
@media screen and (min-width: 1280px) {
  #top .hero__content {
    top: 12.5vh;
    left: 12vw;
    transform: none;
  }
}
#top .hero__title {
  font-size: 20vw;
  font-family: "GFS Didot", serif;
  line-height: 0.9;
  color: #ffffff;
  text-transform: uppercase;
  padding-left: 4%;
  text-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
}
@media screen and (min-width: 768px) {
  #top .hero__title {
    font-size: 17.5vw;
  }
}
@media screen and (min-width: 1280px) {
  #top .hero__title {
    padding-left: 0;
    font-size: 10.41667vw;
  }
}
#top .hero__title-bottom {
  letter-spacing: -0.04em;
}
#top .hero__text {
  font-size: 1.5rem;
  font-weight: 700;
  color: #773d36;
  margin-top: 15px;
  padding-left: 5%;
  padding-right: 5%;
}
@media screen and (min-width: 768px) {
  #top .hero__text {
    font-size: 2rem;
  }
}
@media screen and (min-width: 1280px) {
  #top .hero__text {
    white-space: nowrap;
    font-size: 1.25vw;
    padding-right: 0;
    padding-left: 1.5%;
  }
}
#top .hero__text span {
  display: inline-block;
}
@media screen and (min-width: 1280px) {
  #top .hero__text span {
    display: inline;
  }
}
#top .hero__image {
  position: absolute;
  top: 6.02vh;
  left: 0;
  right: 0;
  text-align: right;
}
#top .hero__image img {
  width: 80px;
}
@media screen and (min-width: 1280px) {
  #top .hero__image img {
    width: 10vw;
  }
}
#top .js-titleTop,
#top .js-titleBottom {
  display: flex;
  overflow: hidden;
}
#top .js-titleTop.js-heroStart span,
#top .js-titleBottom.js-heroStart span {
  transform: translate(0, 0);
}
#top .js-titleTop span {
  display: block;
  transform: translate(0, 105%);
  transition: transform cubic-bezier(0.075, 0.82, 0.165, 1) 0.6s;
}
#top .js-titleTop span:nth-child(2) {
  transition-delay: 0.06s;
}
#top .js-titleTop span:nth-child(3) {
  transition-delay: 0.12s;
}
#top .js-titleTop span:nth-child(4) {
  transition-delay: 0.18s;
}
#top .js-titleBottom span {
  display: block;
  transform: translate(0, 105%);
  transition: transform cubic-bezier(0.075, 0.82, 0.165, 1) 0.6s;
}
#top .js-titleBottom span:nth-child(1) {
  transition-delay: 0.24s;
}
#top .js-titleBottom span:nth-child(2) {
  transition-delay: 0.3s;
}
#top .js-titleBottom span:nth-child(3) {
  transition-delay: 0.36s;
}
#top .js-titleBottom span:nth-child(4) {
  transition-delay: 0.42s;
}
#top .js-titleBottom span:nth-child(5) {
  transition-delay: 0.48s;
}
#top .js-titleBottom span:nth-child(6) {
  transition-delay: 0.54s;
}
#top .js-titleBottom span:nth-child(7) {
  transition-delay: 0.6s;
}
#top .js-text {
  overflow: hidden;
  transform: translate(-100%, 0);
  transition: transform cubic-bezier(0.075, 0.82, 0.165, 1) 1.2s;
  transition-delay: 0.84s;
}
#top .js-text .hero__text-inner {
  display: block;
  transform: translate(100%, 0);
  transition: transform cubic-bezier(0.075, 0.82, 0.165, 1) 1.2s;
  transition-delay: 0.84s;
}
#top .js-text.js-heroStart {
  transform: translate(0, 0);
}
#top .js-text.js-heroStart .hero__text-inner {
  transform: translate(0, 0);
}
#top .company {
  padding-top: 64px;
  padding-bottom: 64px;
  color: #ffffff;
  background-color: #8a5045;
}
@media screen and (min-width: 1280px) {
  #top .company {
    padding: 48px 0 96px;
  }
}
@media screen and (min-width: 1280px) {
  #top .company__inner {
    display: grid;
    grid-template: "title . text-box" "button . text-box"/385px 1fr 608px;
  }
}
#top .company__title {
  color: #ffffff;
}
@media screen and (min-width: 1280px) {
  #top .company__title {
    grid-area: title;
    margin-top: 10px;
    margin-bottom: auto;
  }
}
@media screen and (min-width: 1280px) {
  #top .company__text-box {
    grid-area: text-box;
  }
}
#top .company__sub-title {
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: -0.025em;
  margin-top: 40px;
}
@media screen and (min-width: 768px) {
  #top .company__sub-title {
    font-size: 2.4rem;
  }
}
@media screen and (min-width: 1280px) {
  #top .company__sub-title {
    font-size: 3rem;
    margin-top: 0;
    white-space: nowrap;
  }
}
#top .company__text {
  font-size: 1.6rem;
  line-height: 30px;
  margin-top: 24px;
}
@media screen and (min-width: 1280px) {
  #top .company__text {
    margin-top: 8px;
    width: 608px;
  }
}
#top .company__button {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 1280px) {
  #top .company__button {
    grid-area: button;
    margin-top: auto;
    display: block;
  }
}
#top .section-primary {
  padding-top: 80px;
}
@media screen and (min-width: 1280px) {
  #top .section-primary {
    padding-top: 160px;
  }
}
#top .section-primary--first {
  padding-top: 64px;
}
@media screen and (min-width: 1280px) {
  #top .section-primary--first {
    padding-top: 120px;
  }
}
@media screen and (min-width: 1280px) {
  #top .section-primary__inner {
    padding-bottom: 80px;
    padding: 0 4.6875vw;
    margin: 0 auto;
    background-image: linear-gradient(to bottom, transparent 160px, #fefff0 160px);
  }
}
@media screen and (min-width: 1280px) {
  #top .section-primary__box {
    display: grid;
    grid-template: "image . title" 160px "image . text-box" 1fr "image . button"/5fr 1fr 620px;
  }
}
@media screen and (min-width: 1280px) {
  #top .section-primary__title {
    grid-area: title;
    margin-bottom: 20px;
    margin-left: 10px;
  }
}
#top .section-primary__title--small .title__sub {
  font-size: 11vw;
}
@media screen and (min-width: 768px) {
  #top .section-primary__title--small .title__sub {
    font-size: 8rem;
  }
}
@media screen and (min-width: 1280px) {
  #top .section-primary__text-box {
    grid-area: text-box;
    padding-left: 12px;
    margin-top: 50px;
  }
}
#top .section-primary__sub-title {
  margin-top: 24px;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: -0.025em;
  color: #8a5045;
}
@media screen and (min-width: 1280px) {
  #top .section-primary__sub-title {
    font-size: 3rem;
    margin-top: 0;
  }
}
#top .section-primary__text {
  font-size: 1.6rem;
  line-height: 30px;
  margin-top: 16px;
  color: #646464;
}
@media screen and (min-width: 1280px) {
  #top .section-primary__text {
    margin-top: 30px;
  }
}
#top .section-primary__button {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 1280px) {
  #top .section-primary__button {
    grid-area: button;
    padding-top: 24px;
    margin-top: 0;
  }
}
#top .section-primary__image {
  margin-top: 24px;
}
@media screen and (min-width: 1280px) {
  #top .section-primary__image {
    grid-area: image;
    width: 42.1875vw;
    margin-left: calc(100% - 42.1875vw);
    margin-top: 0;
    max-height: 520px;
    min-height: 400px;
  }
}
#top .section-primary__image--auto {
  max-height: none;
}
#top .section-primary__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#top .section-primary__list {
  max-width: 640px;
  margin: 64px auto 0;
}
@media screen and (min-width: 768px) {
  #top .section-primary__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 1280px) {
  #top .section-primary__list {
    max-width: none;
    margin: 96px 0 0 0;
  }
}
#top .section-secondary {
  width: 100vw;
  overflow-x: hidden;
  padding-top: 64px;
}
@media screen and (min-width: 1280px) {
  #top .section-secondary {
    padding-top: 160px;
  }
}
@media screen and (min-width: 1280px) {
  #top .section-secondary__inner {
    display: grid;
    grid-template: "title . image" "text-box . image" ". . image"/620px 1fr 5fr;
  }
}
@media screen and (min-width: 1280px) {
  #top .section-secondary__title {
    grid-area: title;
    margin-top: 35px;
  }
}
@media screen and (min-width: 1280px) {
  #top .section-secondary__text-box {
    grid-area: text-box;
  }
}
#top .section-secondary__text {
  font-size: 1.6rem;
  line-height: 30px;
  margin-top: 24px;
  color: #646464;
}
@media screen and (min-width: 1280px) {
  #top .section-secondary__text {
    margin-top: 38px;
  }
}
#top .section-secondary__image {
  margin-top: 24px;
}
@media screen and (min-width: 1280px) {
  #top .section-secondary__image {
    grid-area: image;
    width: 42.1875vw;
    margin-right: calc(100% - 42.1875vw);
    margin-top: 0;
    max-height: 520px;
    min-height: 400px;
  }
}
#top .section-secondary__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#top .section-secondary__button {
  margin-top: 40px;
}
@media screen and (min-width: 1280px) {
  #top .section-secondary__button {
    margin-top: 24px;
  }
}
#top .faq {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 64px;
  background-color: #8a5045;
}
@media screen and (min-width: 1280px) {
  #top .faq {
    margin-top: 120px;
  }
}
#top .faq__inner {
  min-height: 400px;
  padding-top: 36px;
  padding-bottom: 36px;
  padding-left: 16px;
  padding-right: 16px;
  background-image: url("./../img/top/top_faq-img@2x.png");
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}
#top .faq__title {
  font-size: 2.4rem;
  color: #ffffff;
  text-align: center;
}
@media screen and (min-width: 1280px) {
  #top .faq__title {
    font-size: 3.6rem;
  }
}
#top .faq__text {
  color: #ffffff;
  text-align: center;
  margin-top: 40px;
  margin-bottom: auto;
}
#top .faq__button .button {
  margin: 24px auto 0;
}
#top .faq__button .button:hover, #top .faq__button .button:focus {
  color: #ffffff;
  background-color: #8a5045;
}
#top .recruit {
  padding-top: 72px;
  padding-bottom: 80px;
}
#top .recruit__inner {
  color: #ffffff;
  background-color: #b75d52;
  padding: 40px 16px;
}
@media screen and (min-width: 768px) {
  #top .recruit__inner {
    padding: 40px 24px;
  }
}
@media screen and (min-width: 1280px) {
  #top .recruit__inner {
    padding: 50px;
    display: grid;
    grid-template: "image . title" "image . text-box" "image . space" "image . button"/1fr 40px 360px;
  }
}
#top .recruit__title {
  color: #ffffff;
}
@media screen and (min-width: 1280px) {
  #top .recruit__title {
    grid-area: title;
  }
}
@media screen and (min-width: 1280px) {
  #top .recruit__text-box {
    grid-area: text-box;
  }
}
#top .recruit__sub-title {
  font-size: 2rem;
  margin-top: 24px;
  letter-spacing: -0.025em;
  font-weight: 700;
}
@media screen and (min-width: 360px) {
  #top .recruit__sub-title {
    font-size: 2.4rem;
  }
}
@media screen and (min-width: 1280px) {
  #top .recruit__sub-title {
    font-size: 3rem;
    margin-top: 30px;
  }
}
#top .recruit__text {
  font-size: 1.6rem;
  margin-top: 8px;
  line-height: 30px;
}
#top .recruit__button {
  margin-top: 40px;
}
@media screen and (min-width: 1280px) {
  #top .recruit__button {
    grid-area: button;
    margin-top: 0;
  }
}
#top .recruit__button a:hover, #top .recruit__button a:focus {
  color: #b75d52;
  /* OR */
}
#top .recruit__image {
  margin-top: 24px;
}
@media screen and (min-width: 1280px) {
  #top .recruit__image {
    grid-area: image;
    margin-top: 0;
  }
}