@import "config/mixin";
@import "config/variables";

#top {
  // hero
  .hero {
    width: 100vw;
    height: calc(100vh - 80px);
    background-image: url("./../img/top/top_fv@2x.png");
    background-size: cover;
    background-position: bottom center;
    background-color: $color-brown1;
    position: relative;
    &__content {
      position: absolute;
      top: 40%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      @include mq("xl") {
        top: 12.5vh;
        left: 12vw;
        transform: none;
      }
    }
    &__title {
      font-size: 20vw;
      font-family: $font-en2;
      line-height: 0.9;
      color: $color-white1;
      text-transform: uppercase;
      padding-left: 4%;
      text-shadow: 0 5px 10px rgba(#000000, 0.16);
      @include mq("md") {
        font-size: 17.5vw;
      }
      @include mq("xl") {
        padding-left: 0;
        font-size: 10.41667vw;
      }
    }
    &__title-bottom {
      letter-spacing: -0.04em;
    }
    &__text {
      font-size: 1.5rem;
      font-weight: 700;
      color: $color-brown3;
      margin-top: 15px;
      padding-left: 5%;
      padding-right: 5%;
      @include mq("md") {
        font-size: 2rem;
      }
      @include mq("xl") {
        white-space: nowrap;
        font-size: 1.25vw;
        padding-right: 0;
        padding-left: 1.5%;
      }
      span {
        display: inline-block;
        @include mq("xl") {
          display: inline;
        }
      }
    }
    &__image {
      position: absolute;
      top: 6.02vh;
      left: 0;
      right: 0;
      text-align: right;
      img {
        width: 80px;
        @include mq("xl") {
          width: 10vw;
        }
      }
    }
  }
  .js-titleTop,
  .js-titleBottom {
    display: flex;
    overflow: hidden;
    &.js-heroStart {
      span {
        transform: translate(0, 0);
      }
    }
  }
  .js-titleTop {
    span {
      display: block;
      transform: translate(0, 105%);
      transition: transform cubic-bezier(0.075, 0.82, 0.165, 1) 0.6s;
      &:nth-child(2) {
        transition-delay: 0.06s;
      }
      &:nth-child(3) {
        transition-delay: 0.12s;
      }
      &:nth-child(4) {
        transition-delay: 0.18s;
      }
    }
  }
  .js-titleBottom {
    span {
      display: block;
      transform: translate(0, 105%);
      transition: transform cubic-bezier(0.075, 0.82, 0.165, 1) 0.6s;
      &:nth-child(1) {
        transition-delay: 0.24s;
      }
      &:nth-child(2) {
        transition-delay: 0.3s;
      }
      &:nth-child(3) {
        transition-delay: 0.36s;
      }
      &:nth-child(4) {
        transition-delay: 0.42s;
      }
      &:nth-child(5) {
        transition-delay: 0.48s;
      }
      &:nth-child(6) {
        transition-delay: 0.54s;
      }
      &:nth-child(7) {
        transition-delay: 0.6s;
      }
    }
  }
  .js-text {
    overflow: hidden;
    transform: translate(-100%, 0);
    transition: transform cubic-bezier(0.075, 0.82, 0.165, 1) 1.2s;
    transition-delay: 0.84s;
    .hero__text-inner {
      display: block;
      transform: translate(100%, 0);
      transition: transform cubic-bezier(0.075, 0.82, 0.165, 1) 1.2s;
      transition-delay: 0.84s;
    }
    &.js-heroStart {
      transform: translate(0, 0);
      .hero__text-inner {
        transform: translate(0, 0);
      }
    }
  }

  // company
  .company {
    padding-top: 64px;
    padding-bottom: 64px;
    color: $color-white1;
    background-color: $color-brown1;
    @include mq("xl") {
      padding: 48px 0 96px;
    }
    &__inner {
      @include mq("xl") {
        display: grid;
        grid-template:
          "title . text-box"
          "button . text-box" /
          385px 1fr 608px;
      }
    }
    &__title {
      color: $color-white1; // OR
      @include mq("xl") {
        grid-area: title;
        margin-top: 10px;
        margin-bottom: auto;
      }
    }
    &__text-box {
      @include mq("xl") {
        grid-area: text-box;
      }
    }
    &__sub-title {
      font-size: 2rem;
      font-weight: 700;
      letter-spacing: -0.025em;
      margin-top: 40px;
      @include mq("md") {
        font-size: 2.4rem;
      }
      @include mq("xl") {
        font-size: 3rem;
        margin-top: 0;
        white-space: nowrap;
      }
    }
    &__text {
      font-size: 1.6rem;
      line-height: 30px;
      margin-top: 24px;
      @include mq("xl") {
        margin-top: 8px;
        width: 608px;
      }
    }
    &__button {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      @include mq("xl") {
        grid-area: button;
        margin-top: auto;
        display: block;
      }
    }
  }

  // section-primary
  .section-primary {
    padding-top: 80px;
    @include mq("xl") {
      padding-top: 160px;
    }
    &--first {
      padding-top: 64px;
      @include mq("xl") {
        padding-top: 120px;
      }
    }
    &__inner {
      @include mq("xl") {
        padding-bottom: 80px;
        padding: 0 4.6875vw;
        margin: 0 auto;
        background-image: linear-gradient(
          to bottom,
          transparent 160px,
          $color-cleam1 160px
        );
      }
    }
    &__box {
      @include mq("xl") {
        display: grid;
        grid-template:
          "image . title" 160px
          "image . text-box" 1fr
          "image . button" /
          5fr 1fr 620px;
      }
    }
    &__title {
      @include mq("xl") {
        grid-area: title;
        margin-bottom: 20px;
        margin-left: 10px;
      }
      &--small {
        .title__sub {
          font-size: 11vw;
          @include mq("md") {
            font-size: 8rem;
          }
        }
      }
    }
    &__text-box {
      @include mq("xl") {
        grid-area: text-box;
        padding-left: 12px;
        margin-top: 50px;
      }
    }
    &__sub-title {
      margin-top: 24px;
      font-size: 2.4rem;
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: -0.025em;
      color: $color-brown1;
      @include mq("xl") {
        font-size: 3rem;
        margin-top: 0;
      }
    }
    &__text {
      font-size: 1.6rem;
      line-height: 30px;
      margin-top: 16px;
      color: $color-gray3;
      @include mq("xl") {
        margin-top: 30px;
      }
    }
    &__button {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      @include mq("xl") {
        grid-area: button;
        padding-top: 24px;
        margin-top: 0;
      }
    }
    &__image {
      margin-top: 24px;
      @include mq("xl") {
        grid-area: image;
        width: 42.1875vw;
        margin-left: calc(100% - 42.1875vw);
        margin-top: 0;
        max-height: 520px;
        min-height: 400px;
      }
      &--auto {
        max-height: none;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__list {
      max-width: 640px;
      margin: 64px auto 0;
      @include mq("md") {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
      @include mq("xl") {
        max-width: none;
        margin: 96px 0 0 0;
      }
    }
  }

  // section-secondary
  .section-secondary {
    width: 100vw;
    overflow-x: hidden;
    padding-top: 64px;
    @include mq("xl") {
      padding-top: 160px;
    }
    &__inner {
      @include mq("xl") {
        display: grid;
        grid-template:
          "title . image"
          "text-box . image"
          ". . image" /
          620px 1fr 5fr;
      }
    }
    &__title {
      @include mq("xl") {
        grid-area: title;
        margin-top: 35px;
      }
    }
    &__text-box {
      @include mq("xl") {
        grid-area: text-box;
      }
    }
    &__text {
      font-size: 1.6rem;
      line-height: 30px;
      margin-top: 24px;
      color: $color-gray3;
      @include mq("xl") {
        margin-top: 38px;
      }
    }
    &__image {
      margin-top: 24px;
      @include mq("xl") {
        grid-area: image;
        width: 42.1875vw;
        margin-right: calc(100% - 42.1875vw);
        margin-top: 0;
        max-height: 520px;
        min-height: 400px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__button {
      margin-top: 40px;
      @include mq("xl") {
        margin-top: 24px;
      }
    }
  }

  // faq
  .faq {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 64px;
    background-color: $color-brown1;
    @include mq("xl") {
      margin-top: 120px;
    }
    &__inner {
      min-height: 400px;
      padding-top: 36px;
      padding-bottom: 36px;
      padding-left: 16px;
      padding-right: 16px;
      background-image: url("./../img/top/top_faq-img@2x.png");
      background-size: cover;
      display: flex;
      flex-direction: column;
      justify-content: stretch;
    }
    &__title {
      font-size: 2.4rem;
      color: $color-white1;
      text-align: center;
      @include mq("xl") {
        font-size: 3.6rem;
      }
    }
    &__text {
      color: $color-white1;
      text-align: center;
      margin-top: 40px;
      margin-bottom: auto;
    }
    &__button {
      .button {
        margin: 24px auto 0;
        &:hover,
        &:focus {
          // OR
          color: $color-white1;
          background-color: $color-brown1;
        }
      }
    }
  }

  // recruit
  .recruit {
    padding-top: 72px;
    padding-bottom: 80px;
    &__inner {
      color: $color-white1;
      background-color: $color-brown2;
      padding: 40px 16px;
      @include mq("md") {
        padding: 40px 24px;
      }
      @include mq("xl") {
        padding: 50px;
        display: grid;
        grid-template:
          "image . title"
          "image . text-box"
          "image . space"
          "image . button" /
          1fr 40px 360px;
      }
    }
    &__title {
      color: $color-white1; // OR
      @include mq("xl") {
        grid-area: title;
      }
    }
    &__text-box {
      @include mq("xl") {
        grid-area: text-box;
      }
    }
    &__sub-title {
      font-size: 2rem;
      margin-top: 24px;
      letter-spacing: -0.025em;
      font-weight: 700;
      @include mq("xs") {
        font-size: 2.4rem;
      }
      @include mq("xl") {
        font-size: 3rem;
        margin-top: 30px;
      }
    }
    &__text {
      font-size: 1.6rem;
      margin-top: 8px;
      line-height: 30px;
    }
    &__button {
      margin-top: 40px;
      @include mq("xl") {
        grid-area: button;
        margin-top: 0;
      }
      a {
        &:hover,
        &:focus {
          color: $color-brown2; /* OR */
        }
      }
    }
    &__image {
      margin-top: 24px;
      @include mq("xl") {
        grid-area: image;
        margin-top: 0;
      }
    }
  }
}
