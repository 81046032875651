//
// fonts
// ---------------------------------
$font-jp: "YakuHanJP", "Noto Sans JP", "ヒラギノ角ゴシック", "Hiragino Sans",
  "メイリオ", "Meiryo", sans-serif;
$font-en1: "Anton", sans-serif;
$font-en2: "GFS Didot", serif;
$font-size: 2rem;
$font-weight-ttl: 700;
$font-weight-txt: 400;
$line-height-ttl: 1.5;
$line-height-txt: 3.5rem;
$letter-spacing-ttl: -0.075em;
$letter-spacing-txt: 0;

//
// colors
// ---------------------------------
// Basic colors
$color-black1: #000000;
$color-white1: #ffffff;
$color-brown1: #8a5045;
$color-brown2: #b75d52;
$color-brown3: #773d36;
$color-brown4: #a7645c;
$color-brown5: #b79995;
$color-brown6: #e5ceca;
$color-cleam1: #fefff0;
$color-gray1: #e8e8e8;
$color-gray2: #cccbcb;
$color-gray3: #646464;
$color-gray4: #707070;
$color-green1: #9efde0;
$color-green2: #32af89;
$color-red1: #ff0000;

// Parts colors
// common
$common-txt-black: $color-black1;
$common-txt-brown: $color-brown3;
$common-bg-brown: $color-brown1;
$common-bg-cleam: $color-cleam1;

// header
$header-txt-white: $color-white1;
$header-txt-brown: $color-brown3;
$header-txt-gray: $color-gray3;
$header-border-brown: $color-brown3;
$header-bg-white: $color-white1;
$header-bg-faq: $color-brown5;
$header-bg-vacancy: $color-brown4;
$header-bg-contact: $color-brown3;

// footer
$footer-txt-white: $color-white1;
$footer-txt-black: $color-black1;
$footer-txt-brown: $color-brown3;
$footer-txt-gray: $color-gray3;
$footer-txt-tel: $color-brown1;
$footer-bg-cleam: $color-cleam1;
$footer-bg-brown: $color-brown1;

// button
// brown
$button-brawn-txt: $color-brown3;
$button-brawn-border: $color-brown3;
$button-brawn-txt-hover: $color-white1;
$button-brawn-bg-hover: $color-brown1;
// brown
$button-brawn-txt: $color-brown3;
$button-brawn-border: $color-brown3;
$button-brawn-txt-hover: $color-white1;
$button-brawn-bg-hover: $color-brown1;
// white
$button-white-txt: $color-white1;
$button-white-border: $color-white1;
$button-white-txt-hover: $color-brown3;
$button-white-bg-hover: $color-white1;

//
// transition
// ---------------------------------
$hover-opacity: 0.8;
$transition-timing-function: ease-out;
$transition-duration: 0.3s;

//
// breakpoints
// ---------------------------------
$breakpoints: (
  "xs": "screen and (min-width: 360px)",
  "sm": "screen and (min-width: 640px)",
  "md": "screen and (min-width: 768px)",
  "lg": "screen and (min-width: 1024px)",
  "xl": "screen and (min-width: 1280px)",
  "2xl": "screen and (min-width: 1536px)",
) !default;

@mixin mq($breakpoint) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
